import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import "../cusAcknowlegment/AckForm/AckForm.scss";

/**
 * Discount Information Section
 * Displays pm the acknowledgment form when pricing level
 * is EMP, SUP, QRD. Handles JSON related to discount information
 * section
 * @param {*} props
 * @returns
 */
const DiscountInformation = ({
    discount,
    birthday,
    customerInformationSection,
}) => {
    //Changes font color of disabled text to black
    const fontColor = {
        style: { color: "rgb(50, 50, 50)" },
    };

    return (
        <div className="group-wrapper">
            <div className="input-field-header">Discount Information</div>
            <Grid container spacing={3}>
                <Grid item xs={6} sm={4}>
                    <div className="input-label">Program Name</div>
                    <div id="program-name" className="field-value">
                        {discount.programName}
                    </div>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <div className="input-label">Incentive Code</div>
                    <div id="incentive-code" className="field-value">
                        {discount.incentiveCode}
                    </div>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <div className="input-label">
                        <label for="postal-code">Postal Code</label>
                    </div>
                    <div id="postal-code" className="field-value">
                        {discount.postalCode}
                    </div>
                </Grid>
                {discount.pricingForAll === "N" && (
                    <>
                        <Grid item xs={6} sm={4}>
                            <div className="input-label">
                                Customer Date of Birth
                            </div>
                            <div id="birthday" className="field-value">
                                {birthday}
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <div className="input-label">
                                Eligible Participant
                            </div>
                            <div
                                id="eligible-participant"
                                className="field-value"
                            >
                                {discount.participant}
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <div className="input-label">Purchaser's Name</div>
                            <div id="purchaser-name" className="field-value">
                                {customerInformationSection.firstName +
                                    " " +
                                    customerInformationSection.lastName}
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <div className="input-label">
                                Relationship to Eligible Participant
                            </div>
                            <div id="relationship" className="field-value">
                                {discount.relationship}
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <div className="input-label">Approval Date</div>
                            <div id="approvalDate" className="field-value">
                                {discount.approvalDate}
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <div className="input-label">
                                {discount.authType === "GM-CERT"
                                    ? "Certificate #"
                                    : "Authorization #"}
                            </div>
                            <div
                                id="authorization-number"
                                className="field-value"
                            >
                                {discount.authorizationNumber}
                            </div>
                        </Grid>
                        {discount.authType !== "GM-CERT" ? (
                            <Grid item xs={6} sm={4}>
                                <div className="input-label">Approval #</div>
                                <div
                                    id="approval-number"
                                    className="field-value"
                                >
                                    {discount.approvalNumber}
                                </div>
                            </Grid>
                        ) : (
                            ""
                        )}
                    </>
                )}
            </Grid>
            <div className="group-bottom"></div>
            <Divider />
        </div>
    );
};

export default DiscountInformation;
