import React, {useState, useContext, useEffect, useRef} from "react";
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import SignaturePad from "react-signature-canvas";
import "./CustomerSig.scss";
import {GlobalContext} from "../../../../GlobalService";


const CustomerSig = (props) => {

    const sigCanvas = useRef({});

    const [openPopup, setOpenPopup] = useState(false);
    const [isEmpty, setIsEmpty] = useState(true);
    const {setSignatureURL} = useContext(GlobalContext)
    
    const handleClick = () => {
        setOpenPopup(true);
    };

    const handleClose = (event) => {
        setIsEmpty(true);
        setOpenPopup(false);
    };

    const clear = () => {
        setIsEmpty(true);
        sigCanvas.current.clear()
    };
    const save = () => setSignatureURL(sigCanvas.current.toDataURL("image/png"));


    return (
        <div className="signature">
            <Button className="signingBox" variant="outlined" onClick={() => handleClick()}>Sign here</Button>
            <Popover
                open={openPopup}
                onClose={handleClose}
            >

                <SignaturePad
                    ref={sigCanvas}
                    canvasProps={{
                        className: "signatureCanvas"
                    }}
                    onBegin={() => setIsEmpty(false)}
                    clearOnResize={false}
                />

                <Button onClick={handleClose}>close</Button>
                <Button onClick={clear}>Clear</Button>
                <Button onClick={save} disabled={isEmpty}>Save</Button>
            </Popover>
        </div>

    );
}

export default CustomerSig;