import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import "../cusAcknowlegment/AckForm/AckForm.scss";
import { getFormattedDate } from './DateFormatter';

const AuthorizedDealerAcknowledgment = (props) => {

    let currentDate = new Date();

    const fontColor = {
        style: {color: 'rgb(50, 50, 50)'}
    }

    return (
        <div className="group-wrapper">
            <div className="input-field-header-black">
                Authorized Dealer's Acknowledgement
            </div>
            <div>The undersigned person, a Dealer Representative, certifies that the information on this application is
                true and correct and the incentive payments have been provided to
                the said purchaser/lessee who has taken delivery of the referenced unit through this dealership and
                that properly completed accurate delivery data has been forwarded to General Motors.
            </div>
            <div className="checkbox_area">
                <Checkbox
                    style={{
                        transform: "scale(1.2)",
                        padding: 0,
                    }}
                    color="primary"
                    checked={true}
                />&nbsp;&nbsp;
                The authorized Dealer acknowledges that all the information
                above is correct.
            </div>
            <div>
                <Grid container spacing={6}>
                    <Grid item xs={4} sm={3}>
                        <div className="input-label">Date</div>
                        <div className="field-value">{getFormattedDate(currentDate)}</div>
                    </Grid>
                    <Grid item xs={8} sm={9}>
                        <div className="input-label">
                            By signing below you are acknowledging and agreeing
                            to the information provided above:
                        </div>
                        <TextField
                            id="customer-signature-box"
                            value={props.acknowledgementData.dealerNm}
                            disabled
                            variant="outlined"
                            inputProps={fontColor}
                            size="small"
                            fullWidth
                        ></TextField>
                    </Grid>
                </Grid>
            </div>

            <div className="group-bottom">
                <div><span style={{fontWeight: "bold"}}>Dealer Note: </span>This is a required document and it must be
                    completed, signed, and retained in EVERY DEAL FILE for all customers even if there are no incentives
                    or rate support available. A copy of the completed form should be provided to the customer.
                    (GM379509-08012018) (12/01/2021)
                </div>
            </div>
            <Divider/>
        </div>
    );
};

export default AuthorizedDealerAcknowledgment;