import axios from "axios";

export async function retrieveSignedCIAF(vinNumber, bundleId, bundleVersion) {

    const req = {
        "vin": vinNumber,
        "bundleId": bundleId,
        "bundleVersion": bundleVersion,
        "originationSource": "CONNECT_UI",
    };


    let gateway = "";
    if (window.location.hostname === "localhost") {
        gateway = process.env.REACT_APP_BACKEND_GATEWAY;
    } else {
        gateway = window._env_.REACT_APP_BACKEND_GATEWAY;
    }
    const payload = JSON.stringify(req)
    const response = await axios({
        method: "post",
        data: payload,
        url: gateway + "/incentives/acknowledgement/ciafRetrieve",
        headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("id_token"),
        }
    });
    return response;
}