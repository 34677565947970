import Index from "./client/pages/index";
import GlobalService from "./GlobalService";

const App = () => {
    return (
        <div className="app">
            <GlobalService>
                <Index />
            </GlobalService>
        </div>
    );
};

export default App;
