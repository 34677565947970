import { useState } from "react";
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import "../cusAcknowlegment/AckForm/AckForm.scss";

const CustomerInfo = ({customerInformationSection, acknowledgementData}) => {

    const [primaryBusinessName, setPrimaryBusinessName] = useState(acknowledgementData?.primaryBusinessNm)
    const [secondaryBusinessName, setSecondaryBusinessName] = useState(acknowledgementData?.secondaryBusinessNm)
    const [primaryFan, setPrimaryFan] = useState(acknowledgementData?.primaryFAN)
    const [secondaryFan, setSecondaryFan] = useState(acknowledgementData?.secondaryFAN)
    
    const deliveryType = parseInt(acknowledgementData.deliveryType);
    
    const PRIM_BUS_NAME_DEL_TYPES = [20, 35, 36, 38, 40, 14, 18, 29, 11];

    const PRIM_FAN_DEL_TYPES = [14, 40, 20, 35, 36, 38];

    const SEC_BUS_NAME_DEL_TYPES = [14];

    const SEC_FAN_ARR = [14];
 

    return (
        <div className="group-wrapper">
            <div className="input-field-header">Customer Information</div>
            <Grid container spacing={1}>
                <Grid item xs={6} sm={3} >
                    <div className="input-label">Customer First Name</div>
                    <div id="first-name-box" className="field-value">{customerInformationSection.firstName}</div>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <div className="input-label">Customer Last Name</div>
                    <div id="last-name-box" className="field-value">{customerInformationSection.lastName}</div>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <div className="input-label">Postal Code</div>
                    <div id="postal-code-box" className="field-value">{acknowledgementData.zipCode}</div>
                </Grid>
                    <Grid item xs={6} sm={3}>
                        <div className="input-label">
                            <label htmlFor="company-partner-name-box">
                                {!PRIM_BUS_NAME_DEL_TYPES.includes(deliveryType) ? "Company/Partner Name" : "Primary Business Name" }
                            </label>
                        </div>
                        <div>
                            {!PRIM_BUS_NAME_DEL_TYPES.includes(deliveryType) ?  customerInformationSection.companyName : primaryBusinessName}
                        </div>
                    </Grid>
            </Grid>
            {(primaryFan != "" || secondaryBusinessName != "" || secondaryFan != "") && <div className="group-bottom"></div>}
            <Grid container spacing={1}>
                
                {PRIM_FAN_DEL_TYPES.includes(deliveryType)  && 
                <Grid item xs={6} sm={3}>
                    <div className="input-label">Primary FAN</div>
                    <div>
                    {primaryFan}                            
                    </div>
                </Grid>}

                {SEC_BUS_NAME_DEL_TYPES.includes(deliveryType)  &&
                <Grid item xs={6} sm={3}>
                    <div className="input-label">Secondary Business Name</div>
                    <div>
                    {secondaryBusinessName}
                    </div>
                </Grid>}

                {SEC_FAN_ARR.includes(deliveryType) && 
                <Grid item xs={6} sm={3}>
                    <div className="input-label">Secondary FAN</div>
                    <div>
                    {secondaryFan}
                    </div>
                </Grid>}
            </Grid>

            <div className="group-bottom"></div>
            <Divider/>
        </div>
    );
};

export default CustomerInfo;
