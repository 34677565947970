import { useEffect, useState, useContext } from "react";
import "./AckForm.scss";
import Button from "@mui/material/Button";
import CustomerInfo from "../CustomerInfo";
import ProgramVehicleInfo from "../ProgramVehicleInfo";
import DealerInfo from "../DealerInfo";
import CustomerIncentiveProg from "../CustomerIncentiveProg";
import PurchaserAcknowledgement from "../PurchaserAcknowledgement";
import DiscountInformation from "../DiscountInformation";
import AuthorizedDealerAcknowledgment from "../AuthorizedDealerAcknowledgment";
import CustomerAgreement from "../CustomerAgreement";
import AdditionalComments from "../AdditionalComments";
import CustomerSig from "../CustomerSig/CustomerSig";
import CustomerInitials from "../CustomerInitials";
import GMSupplierDiscount from "../GMSupplierDiscount";
import DealerAgreement from "../DealerAgreement";
import {
    recordAcknowledgement,
    updateCiafSignedFlag,
} from "../../../services/acknowledgementServices/AcknowledgementServices";
import { GlobalContext } from "../../../../GlobalService";
import PrintModal from "../PrintModal/PrintModal";
import { dashFormattedDate, getFormattedDateNoUTC } from "../DateFormatter";
import Grid from "@mui/material/Grid";

const AckForm = ({
    incentiveData,
    discountInformation,
    priceLevel,
    hasAuthCode,
    priceLevelNum,
    discountProgram,
    acknowledgementData,
    customerIncentives,
    setDiscountProgram,
    pricingForAll,
    formulaPricing,
}) => {
    const [show, setShow] = useState(false);
    const [additionalComments, setAdditionalComments] = useState({
        comments: acknowledgementData?.ciafCommentText
            ? acknowledgementData?.ciafCommentText
            : "",
    });
    const {
        signatureURL,
        initialURL,
        completeForm,
        setCompleteForm,
        userData,
        acknowledgmentFormData,
        setAcknowledgmentFormData,
        disablePurchaserAcknowledgement,
        disableCustomerAgreement,
        disableInitials,
        customerData,
    } = useContext(GlobalContext);

    const [dealerInformation, setDealerInformation] = useState({
        dealerName: "placeholder",
        division: "placeholder",
        bac: "",
        dealerCode: "placeholder",
        sellSource: "",
    });
    const [customerIncentiveProgram, setCustomerIncentiveProgram] = useState({
        isVisible: true,
        incentiveList: [
            {
                incentiveCode: "",
                incentiveProgramDesc: "",
                qualifier: "",
                qualifierName: "",
                documents: false,
                transferred: false,
                amount: "",
            },
        ],
        totalAmount: "",
    });
    const [customerInformationSection, setCustomerInformationSection] =
        useState({
            firstName: customerData?.first_name,
            lastName: customerData?.last_name,
            companyName: acknowledgementData?.partnerNm
                ? acknowledgementData?.partnerNm
                : "",
            companyNameRequired: false,
        });
    const [isLoading, setisLoading] = useState(true);
    const [purchaserAcknowledgment, setPurchaserAcknowledgment] = useState({});
    const [dealerAcknowledgment, setDealerAcknowledgment] = useState({
        dealerAck: acknowledgementData?.ciafDealerAckFlg
            ? acknowledgementData?.ciafDealerAckFlg
            : false,
    });
    const [dealerAgreement, setDealerAgreement] = useState(
        acknowledgementData?.cdaDealerAckFlg
            ? acknowledgementData?.cdaDealerAckFlg
            : false
    );
    const [birthday, setBirthday] = useState(discountInformation.birthday);
    const [docFeeDisclosure, setDocFeeDisclosure] = useState("none");
    const [vehicleInformation, setVehicleInformation] = useState({
        deliveryDate: acknowledgementData?.deliveryDate,
        deliveryTypeCode: acknowledgementData?.deliveryType,
        newVin: acknowledgementData?.vin,
        modelYear: acknowledgementData?.year,
        make: acknowledgementData?.make,
        model: acknowledgementData?.model,
    });

    function handleSubmit() {
        recordAcknowledgement(
            acknowledgmentFormData,
            signatureURL,
            initialURL
        ).then((res) => {
            if (
                res.response.data.status.message === "SUCCESS" ||
                res.response.data.status.message === "updated"
            ) {
                setShow(true);
                updateCiafSignedFlag(acknowledgementData?.bundleId);
            } else setShow(false);
        });
    }

    const renderBrandImage = () => {
        let image;
        switch (acknowledgementData?.make) {
            case "Cadillac":
            case "Buick":
            case "GMC":
            case "Chevrolet":
                image = require(`../../../images/${acknowledgementData?.make}/header-logo.png`);
                break;
            default:
                image = require(`../../../images/GM/header-logo.png`);
        }
        return image;
    };

    function handleClick() {
        setCompleteForm(true);
    }

    const isCompleteFormDisabled = () => {
        return (
            disablePurchaserAcknowledgement ||
            disableCustomerAgreement ||
            !signatureURL ||
            disableInitials
        );
    };

    const getProgramType = () => {
        if ((priceLevel == "NON" || priceLevel == "") && !hasAuthCode) {
            return "CIAF";
        } else if (priceLevel == "NON" && hasAuthCode) {
            return "Affinity";
        } else if (
            hasAuthCode &&
            (priceLevel == "EMP" || priceLevel == "SUP" || priceLevel == "QRD")
        ) {
            return "CIAF/CDA";
        } else {
            return "";
        }
    };

    const updateAckFormData = () => {
        let cdaTable = [];
        if (
            (hasAuthCode || pricingForAll === "Y" || formulaPricing) &&
            priceLevel !== "NON" &&
            JSON.stringify(discountProgram) !== "{}"
        ) {
            cdaTable = [
                {
                    cdaAmt: discountProgram?.amountOne.replace(/[$,]/g, ""),
                    descSequenceNbr: 1,
                    itemChargeType: discountProgram?.itemOne,
                    programNbr: priceLevelNum,
                },
                {
                    cdaAmt: discountProgram?.amountTwo.replace(/[$,]/g, ""),
                    descSequenceNbr: 2,
                    itemChargeType: discountProgram?.itemTwo,
                    programNbr: priceLevelNum,
                },
                {
                    cdaAmt: discountProgram?.amountThree.replace(/[$,]/g, ""),
                    descSequenceNbr: 3,
                    itemChargeType: discountProgram?.itemThree,
                    programNbr: priceLevelNum,
                },
                {
                    cdaAmt: discountProgram?.amountFour.replace(/[$,]/g, ""),
                    descSequenceNbr: 4,
                    itemChargeType: discountProgram?.itemFour,
                    programNbr: priceLevelNum,
                },
                {
                    cdaAmt: discountProgram?.amountFive.replace(/[$,]/g, ""),
                    descSequenceNbr: 5,
                    itemChargeType: discountProgram?.itemFive,
                    programNbr: priceLevelNum,
                },
            ];
        }

        let discountIncentive = {};
        let discountInfo = [];

        if (hasAuthCode || pricingForAll === "Y") {
            discountInformation.discountArry.map((ele) => {
                discountIncentive = {
                    approvalDt: ele.approvalDate
                        ? getFormattedDateNoUTC(ele.approvalDate)
                        : "",
                    bundleNbr: acknowledgementData?.bundleVersionNbr,
                    deliveryTypeId: acknowledgementData?.deliveryType,
                    discountIncentiveCd: ele.incentiveCode,
                    discountProgramNm: ele.programName,
                    programNbr: ele.programNbr,
                    purchaseType: ele.purchaseType,
                    approvalNum: ele.approvalNumber,
                };
                discountInfo.push(discountIncentive);
            });
        }

        setAcknowledgmentFormData({
            additionalComments: additionalComments.comments,
            bacCode: dealerInformation.bac,
            bundleId: acknowledgementData?.bundleId,
            bundleVersion: acknowledgementData?.bundleVersionNbr,
            cdaCustomerAckFlg: false,
            cdaCustomerSignedDt: dashFormattedDate(new Date()),
            cdaDealerAckFlg: dealerAgreement,
            cdaDealerSignedDt: dashFormattedDate(new Date()),
            cdaTotalAmount: "",
            ciafCommentText: additionalComments.comments,
            ciafCustomerAckFlg: true,
            ciafCustomerSignedDt: dashFormattedDate(new Date()),
            ciafDealerAckFlg: dealerAcknowledgment.dealerAck,
            ciafDealerSignedDt: dashFormattedDate(new Date()),
            customerDealerAgreementTableRequestList: cdaTable,
            customerEmail: userData?.email_address,
            customerIncentiveProgramAcknowledgementRequestList:
                customerIncentiveProgram.customerIncentiveProgramAcknowledgementRequestList,
            customerProfileId: "",
            customerSnapshotId: "",
            dealerApprovalDate: dashFormattedDate(new Date()),
            dealerName: dealerInformation.dealerName,
            dealerSignatureContent: "",
            dealerUserId: dealerInformation.dealerCode,
            deliveryDate: vehicleInformation.deliveryDate,
            deliveryType: vehicleInformation.deliveryTypeCode,
            discountInformationRequestList: discountInfo,
            discountTableRowText: "",
            division: dealerInformation.division,
            documentFee: acknowledgmentFormData?.documentFee,
            firstName: customerInformationSection.firstName,
            incentiveList: {
                incentives: customerIncentiveProgram.incentiveList,
            },
            employeeDiscountInfo: {
                employeeDiscounts: [
                    {
                        itemChargeType: discountProgram.itemOne,
                        itemChargeTypeAmount: discountProgram.amountOne,
                        lineItemCode: 1,
                    },
                    {
                        itemChargeType: discountProgram.itemTwo,
                        itemChargeTypeAmount: discountProgram.amountTwo,
                        lineItemCode: 2,
                    },
                    {
                        itemChargeType: discountProgram.itemThree,
                        itemChargeTypeAmount: discountProgram.amountThree,
                        lineItemCode: 3,
                    },
                    {
                        itemChargeType: discountProgram.itemFour,
                        itemChargeTypeAmount: discountProgram.amountFour,
                        lineItemCode: 4,
                    },
                    {
                        itemChargeType: discountProgram.itemFive,
                        itemChargeTypeAmount: discountProgram.amountFive,
                        lineItemCode: 5,
                    },
                    {
                        itemChargeType: discountProgram.itemSix,
                        itemChargeTypeAmount: discountProgram.amountSix,
                        lineItemCode: 6,
                    },
                ],
            },

            incentiveTotalAmount: customerIncentiveProgram.totalAmount,
            isDiscountTableVisible:
                (priceLevel === "EMP" || priceLevel === "SUP") &&
                (hasAuthCode || pricingForAll === "Y")
                    ? "Y"
                    : "N",
            lastName: customerInformationSection.lastName,
            make: vehicleInformation.make,
            model: vehicleInformation.model,
            modificationBy: "",
            orderType: "",
            originationSource: "CONNECT_UI",
            partnerNm: "",
            sellSource: dealerInformation.sellSource,
            submitToPcfFlg: true,
            signatureType: "E",
            siteCode: dealerInformation.dealerCode,
            vin: vehicleInformation.newVin,
            year: vehicleInformation.modelYear,
            zipCode: acknowledgementData.zipCode,
            pricingLevelCd: priceLevel,
        });
    };

    useEffect(() => {
        updateAckFormData();
    }, [
        customerInformationSection,
        vehicleInformation,
        dealerInformation,
        customerIncentiveProgram,
        purchaserAcknowledgment,
        dealerAcknowledgment,
        additionalComments,
        discountInformation,
        discountProgram,
        dealerAgreement,
    ]);

    // Set this up for refresh needs. Updates parts of state objects that are dependent on userData when it finally gets
    // set - TG
    useEffect(() => {
        setCustomerInformationSection((prevState) => {
            prevState.firstName = customerData?.first_name;
            prevState.lastName = customerData?.last_name;
            return { ...prevState };
        });
        setAcknowledgmentFormData((prevState) => {
            prevState.customerEmail = userData?.email_address;
            return { ...prevState };
        });
    }, [userData]);

    return (
        <div className="form-container">
            <div className="brand-header">
                <img
                    className={acknowledgementData?.make}
                    src={renderBrandImage()}
                    alt=""
                />
                <span> Customer Incentive Acknowledgement Form</span>
            </div>
            <CustomerInfo
                customerInformationSection={customerInformationSection}
                setCustomerInformationSection={setCustomerInformationSection}
                acknowledgementData={acknowledgementData}
            />
            <ProgramVehicleInfo vehicleInformation={vehicleInformation} />
            <DealerInfo
                dealerInformation={dealerInformation}
                acknowledgementData={acknowledgementData}
            />
            <CustomerIncentiveProg
                customerIncentiveProgram={customerIncentiveProgram}
                setCustomerIncentiveProgram={setCustomerIncentiveProgram}
                discountInformation={discountInformation}
                discountProgram={discountProgram}
                acknowledgmentFormData={acknowledgmentFormData}
                setAcknowledgmentFormData={setAcknowledgmentFormData}
                incentiveData={incentiveData}
                acknowledgementData={acknowledgementData}
                customerIncentives={customerIncentives}
                hasAuthCode={hasAuthCode}
                priceLevel={priceLevel}
            />
            <PurchaserAcknowledgement
                acknowledgementData={acknowledgementData}
                purchaserAcknowledgement={purchaserAcknowledgment}
            />
            <AuthorizedDealerAcknowledgment
                acknowledgementData={acknowledgementData}
                dealerAcknowledgement={dealerAcknowledgment}
            />
            {(hasAuthCode || pricingForAll === "Y") &&
                discountInformation.discountArry.length &&
                discountInformation.discountArry.map((discount) => {
                    return (
                        <>
                            <DiscountInformation
                                discount={discount}
                                pricingForAll={pricingForAll}
                                birthday={birthday}
                                customerInformationSection={
                                    customerInformationSection
                                }
                            />
                        </>
                    );
                })}
            {((priceLevel !== "NON" &&
                (hasAuthCode || pricingForAll === "Y")) ||
                formulaPricing) &&
                JSON.stringify(discountProgram) !== "{}" && (
                    <GMSupplierDiscount
                        discountProgram={discountProgram}
                        pricingForAll={pricingForAll}
                        setDiscountProgram={setDiscountProgram}
                        priceLevel={priceLevel}
                        hasAuthCode={hasAuthCode}
                        acknowledgementData={acknowledgementData}
                        formulaPricing={formulaPricing}
                    />
                )}

            {(getProgramType(priceLevel, hasAuthCode) !== "CIAF" ||
                pricingForAll === "Y" ||
                formulaPricing) && (
                <CustomerAgreement
                    priceLevel={priceLevel}
                    discountProgram={discountProgram}
                    setDiscountProgram={setDiscountProgram}
                    getProgramType={getProgramType}
                    acknowledgementData={acknowledgementData}
                    incentiveData={incentiveData}
                    hasAuthCode={hasAuthCode}
                    pricingForAll={pricingForAll}
                    setDocFeeDisclosure={setDocFeeDisclosure}
                    docFeeDisclosure={docFeeDisclosure}
                    formulaPricing={formulaPricing}
                />
            )}
            {(priceLevel === "EMP" || priceLevel === "SUP") &&
                (hasAuthCode || pricingForAll === "Y" || formulaPricing) &&
                JSON.stringify(acknowledgementData) !== "{}" && (
                    <DealerAgreement
                        priceLevel={priceLevel}
                        acknowledgementData={acknowledgementData}
                        pricingForAll={pricingForAll}
                        formulaPricing={formulaPricing}
                    />
                )}

            <AdditionalComments additionalComments={additionalComments} />

            {!completeForm ? (
                <div className="complete-container">
                    <div className="text">
                        “By signing your name below in the signature line and
                        providing your initials the form will be populated where
                        applicable with the information.”
                    </div>
                    <Grid spacing={3}>
                        <div>
                            <div className="complete-button-container">
                                <Grid xs={12} md={4}>
                                    <div className="item-container">
                                        <div className="signature-section">
                                            Purchaser's Signature
                                            {!signatureURL ? (
                                                <CustomerSig />
                                            ) : (
                                                <div className="signature-container">
                                                    <img
                                                        src={signatureURL}
                                                        alt="Purchaser's Signature"
                                                        className="signatureimg"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                                {priceLevel !== "NON" &&
                                    (hasAuthCode ||
                                        pricingForAll === "Y" ||
                                        formulaPricing) &&
                                    JSON.stringify(discountProgram) !==
                                        "{}" && (
                                        <Grid xs={12} md={4}>
                                            <div className="item-container">
                                                <div className="signature-section">
                                                    Purchaser's Initials
                                                    {!initialURL ? (
                                                        <CustomerInitials />
                                                    ) : (
                                                        <img
                                                            src={initialURL}
                                                            alt="Purchaser's Initials"
                                                            className="initialimg"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </Grid>
                                    )}
                                <Grid xs={12} sm={4}>
                                    <div className="item-container">
                                        <Button
                                            className="gds-button-primary complete-form-button"
                                            variant="contained"
                                            color="primary"
                                            onClick={handleClick}
                                            disabled={isCompleteFormDisabled()}
                                        >
                                            Complete Form
                                        </Button>
                                    </div>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </div>
            ) : (
                <div className="submitt-button-container">
                    <div className="text">
                        “By clicking Submit, you are acknowledging all of the
                        information in the above form is correct and you agree.”
                    </div>

                    {/* <button className="open-modal" onClick={() => setShow(true)}>Open Modal</button> */}

                    <div className="submit-modal-div">
                        <PrintModal
                            acknowledgementData={acknowledgementData}
                            onClose={() => setShow(false)}
                            show={show}
                            vin={vehicleInformation.newVin}
                        />
                    </div>
                    <div>
                        <Button
                            className="gds-button-primary"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={isCompleteFormDisabled()}
                        >
                            Submit Form
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AckForm;
