import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import "../cusAcknowlegment/AckForm/AckForm.scss";
import { getFormattedDateNoUTC } from "./DateFormatter";

const ProgramVehicleInfo = ({vehicleInformation}) => {


    return (
        <div className="group-wrapper">
            <div className="input-field-header">
                Program and Vehicle Information
            </div>
            <Grid container spacing={3}>
                <Grid item xs={6} sm={4}>
                    <div className="input-label">Delivery Date</div>
                    <div id="delivery-date-box" className="field-value">{getFormattedDateNoUTC(vehicleInformation.deliveryDate)}</div>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <div className="input-label">Delivery Type Code</div>
                    <div id="delivery-type-code-box" className="field-value">{"0" + vehicleInformation.deliveryTypeCode}</div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div className="input-label">VIN</div>
                    <div id="new-vin-box" className="field-value">{vehicleInformation.newVin}</div>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <div className="input-label">Model Year</div>
                    <div id="model-year-box" className="field-value">{vehicleInformation.modelYear}</div>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <div className="input-label">Make</div>
                    <div id="make-box" className="field-value">{vehicleInformation.make}</div>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <div className="input-label">Model</div>
                    <div id="model-box" className="field-value">{vehicleInformation.model}</div>
                </Grid>
            </Grid>
            <div className="group-bottom"></div>
            <Divider/>
        </div>
    );
};

export default ProgramVehicleInfo;
