import React, {useContext} from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {GlobalContext} from "../../../GlobalService";
import "./Header.scss";

const Header = (props) => {
    const {logoutClicked, setLogoutClicked} = useContext(GlobalContext);

    const renderBrandImage = () => {
        let image;
        switch (props?.acknowledgementData?.make) {
            case "Cadillac":
            case "Buick":
            case "GMC":
            case "Chevrolet":
                image = require(`../../images/${props?.acknowledgementData?.make}/header-logo.png`);
                break;
            default:
                image = require(`../../images/GM/header-logo.png`);
        }
        return image;
    };

    const logoutFunctionality = () => {
        setLogoutClicked(true);
        localStorage.clear();
    };

    if (sessionStorage.getItem("id_token")) {
        let url = window.location.href.substring(
            0,
            window.location.href.lastIndexOf("#state")
        );
        window.history.pushState({}, document.title, url);
    }

    return (
        <div className="header">
            {!logoutClicked && (
                <div>
                    <Toolbar>
                        {/* <img
                            className={props?.acknowledgementData?.make}
                            src={renderBrandImage()}
                            id="brand-logo"
                            alt="GM brand header image"
                        /> */}
                        <div id="emptydiv"/>
                        <IconButton>
                            <ExitToAppIcon
                                onClick={() => logoutFunctionality()}
                            />
                        </IconButton>
                    </Toolbar>
                    <AppBar position="static">
                        <nav>
                            <span id="topnav-left">
                                Please read the following form closely. In order
                                to continue, you will need to review and
                                acknowledge each appropriate box below before
                                submitting.
                            </span>
                            <span id="topnav-right">
                                {new Date().toLocaleString("en-us", {
                                    month: "short",
                                    year: "numeric",
                                    day: "numeric",
                                })}{" "}
                                &nbsp;
                            </span>
                        </nav>
                    </AppBar>

                    {logoutClicked && (
                        <Toolbar>
                            <img
                                className={props?.acknowledgementData?.make}
                                src={renderBrandImage()}
                                id="brand-logo"
                                alt="GM brand header image"
                            />
                            <div id="emptydiv"/>
                            <IconButton>
                                <ExitToAppIcon
                                    onClick={() => logoutFunctionality()}
                                />
                            </IconButton>
                        </Toolbar>
                    )}
                </div>
            )}
        </div>
    );
};

export default Header;
