import React from "react";
import {useState, useEffect, useContext} from "react";
import BottomLogos from "../../images/GM/brands.png";
import {Button, TextField} from "@mui/material";
import {
    confirmCode,
    sendCode,
} from "../../services/otpServices/ConfirmCodeService";
import {getUserProfileId} from "../../services/getProfile/GetProfileService";
import {GlobalContext} from "../../../GlobalService";
import "./ConfirmationCodePage.scss";

const ConfirmationPage = (props) => {
    const {vinBrand, userProfileId, setUserProfileId} = useContext(GlobalContext);

    let email = "";
    let profileId = "";

    const [otp, setOtp] = useState("");
    const [sendCodeResponse, setSendCodeResponse] = useState(null);
    const [showTimer, setShowTimer] = useState(false);

    const [pageTitle, setPageTitle] = useState(
        <h1>We sent you an email containing a confirmation code.</h1>
    );

    const sendInitialCode = () => {
        if (profileId !== "" && !sessionStorage.getItem("initialCodeSent")) {
            sendCode(profileId, email).then((res) => {
                setSendCodeResponse(res);
                sessionStorage.setItem("initialCodeSent", "true");
            });
        }
    };

    const requestCode = () => {
        sendCode(userProfileId, email).then((res) => {
            setSendCodeResponse(res);

            if (res?.data?.status?.message == "SUCCESS") {
                setPageTitle(
                    <h1>
                        We sent a new email message containing a new
                        confirmation code.
                    </h1>
                );
            }
        });
    };

    const submitCode = () => {
        if (otp === "") return;

        confirmCode(otp, userProfileId).then((res) => {
            if (res?.data?.data?.status == "SUCCESS") {
                props.passConfirmedCode(true);
                sessionStorage.setItem("confirmedCode", "set");
            } else if (res?.data?.data?.statusCode == "EXPIRED") {
                sendCode(userProfileId).then((res) => {
                    setSendCodeResponse(res);
                });
                setPageTitle(
                    <h1>
                        Code entered has expired.
                        <br/> We sent a new email message containing a new
                        confirmation code.
                    </h1>
                );
            } else if (res?.data?.data?.statusCode == "PASSCODE_NOT_MATCH") {
                setPageTitle(
                    <h1>
                        Code entered is not valid, please try again or request a
                        new code.
                    </h1>
                );
            } else if (res?.data?.data?.statusCode == "CONFIRM_LIMIT_REACHED") {
                setPageTitle(
                    <h1>
                        Attempt limit has been reached.
                        <br/> We sent a new email message containing a new
                        confirmation code
                    </h1>
                );
                sendCode(userProfileId).then((res) => {
                    setSendCodeResponse(res);
                });
            }
        });
    };

    const filterTimerMessage = (responseMessage) => {
        let filteredMessage;

        const rawResponse = JSON.stringify(responseMessage);

        const responseJSON = rawResponse
            .split("responseFromService:")[1]
            .split(`"`)[0]
            .trim();

        const messageDescription = responseJSON
            .split("messageDescription:")[1]
            .split("}")[0];

        filteredMessage = messageDescription.split("|").map((x) => x.trim());
        return filteredMessage;
    };

    const formatTimer = (message) => {
        // Extract time from message
        const numberRegex = /[0-9]{1,}/g;
        let timeRemaining = message[1].match(numberRegex)
            ? Number(message[1].match(numberRegex))
            : undefined;
        let displayMinutes = false;

        // Put time in minutes
        if (timeRemaining > 60) {
            timeRemaining = Math.round(timeRemaining / 60);
            displayMinutes = true;
        }

        // Replace time in message
        if (displayMinutes) {
            message[1] = message[1]
                .replace(numberRegex, timeRemaining)
                .replace("seconds", timeRemaining > 1 ? "minutes" : "minute");
        } else {
            message[1] = message[1].replace(
                "seconds",
                timeRemaining > 1 ? "seconds" : "second"
            );
        }

        return message;
    };

    const renderTimer = () => {
        // Handle Error
        if (!sendCodeResponse?.data?.status?.message) return;

        const sendCodeMessage = filterTimerMessage(sendCodeResponse.data.status.message);
        const timer = formatTimer(sendCodeMessage);

        return (
            <>
                {timer[0]}. <br/> {timer[1]}.
            </>
        );
    };

    // Images default to Chevrolet Brand if no vinBrand
    const renderImage = (type) => {
        let image;
        switch (vinBrand) {
            case "Cadillac":
            case "Buick":
            case "GMC":
            case "Chevrolet":
                image = require(`../../images/${vinBrand}/${type}.png`);
                break;
        }
        return image;
    };

    useEffect(() => {
        email = props?.email;
    }, []);

    useEffect(() => {
        getUserProfileId(email).then((res) => {
            profileId = res?.data?.data
            setUserProfileId(profileId);
            sendInitialCode();
        });
    }, []);


    // Handle timer display
    useEffect(() => {
        let mounted = true;

        // We only have an error message on 400 status
        const error = sendCodeResponse?.data?.status?.code == 400;
        mounted && setShowTimer(error);

        // Cleanup memory
        return () => {
            setShowTimer(false);
            mounted = false;
        };
    }, [sendCodeResponse]);

    return (
        <div className="confirmation-page-container">
            <div className="side-image-div">
                {vinBrand && (
                    <img
                        className="side-image"
                        src={renderImage("vehicle")}
                        alt="Chevy"
                    />
                )}
            </div>
            <div className="confirmation-page-body">
                <div className="logo-image-div">
                    {vinBrand && (
                        <img
                            className="logo-image"
                            src={renderImage("header-logo")}
                            alt="Logo"
                        />
                    )}
                </div>

                <div id="code-body" className="code-body">
                    <div id="code-body-header" className="page-spacer">
                        {pageTitle}
                        <b>
                            If you do not see your code immediately, it may take
                            a few minutes. Please also check your spam or junk
                            folder.
                            <br/>
                            <br/> To keep your information safe, your code will
                            expire in 30 minutes.
                        </b>
                    </div>

                    <div id="otp-form">
                        <TextField
                            inputProps={{maxLength: 6}}
                            label="Confirmation Code"
                            value={otp}
                            onChange={(event) => setOtp(event.target.value)}
                        />

                        {showTimer ? (
                            <p className="timer">{renderTimer()}</p>
                        ) : (
                            <div className="timer page-spacer"/>
                        )}

                        <Button
                            id="next-button"
                            onClick={() => submitCode()}
                            //disabled={showTimer}
                            size="large"
                            variant="outlined"
                        >
                            Next
                        </Button>
                    </div>

                    <div id="code-body-footer">
                        <button
                            className="request-button page-spacer"
                            onClick={() => requestCode()}
                        >
                            Didn't get your confirmation code?
                        </button>
                        <p>
                            Need help? Give us a call at <u>(888) 466-7827</u>
                        </p>
                        <br/>
                        <h3>
                            One Sign In, All your rewards, vehicles, and apps.
                        </h3>
                        <img
                            className="bottom-logos"
                            src={BottomLogos}
                            alt="BottomLogos"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationPage;
