import { useState, createContext } from "react";

export const GlobalContext = createContext();

const GlobalService = (props) => {
    const [vinBrand, setVinBrand] = useState("");
    const [signatureURL, setSignatureURL] = useState(null);
    const [initialURL, setInitialURL] = useState(null);
    const [completeForm, setCompleteForm] = useState(false)
    const [disablePurchaserAcknowledgement,setDisablePurchaserAcknowledgement] =useState(false);
    const [disableCustomerAgreement,setDisableCustomerAgreement]=useState(false);
    const [disableInitials,setDisableInitials]=useState(false);
    const [userData, setUserData] = useState();
    const [customerData, setCustomerData] = useState();
    const [logoutClicked, setLogoutClicked] = useState(false);
    const [acknowledgmentFormData, setAcknowledgmentFormData] = useState({});
    const [userProfileId, setUserProfileId] = useState("");

    return (
        <GlobalContext.Provider
            value={{
                vinBrand,
                setVinBrand,
                signatureURL,
                setSignatureURL,
                completeForm,
                setCompleteForm,
                userData, 
                setUserData,
                customerData, 
                setCustomerData,
                logoutClicked,
                setLogoutClicked,
                acknowledgmentFormData, 
                setAcknowledgmentFormData,
                disablePurchaserAcknowledgement,
                setDisablePurchaserAcknowledgement,
                initialURL,
                setInitialURL,
                disableCustomerAgreement,
                setDisableCustomerAgreement,
                disableInitials,
                setDisableInitials,
                userProfileId, 
                setUserProfileId
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    );
};

export default GlobalService;
