import { createRoot } from "react-dom/client";
import './index.css';
import App from './App';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";


let rootElement = document.getElementById("root");
if (!rootElement) {
	console.error("There was an issue finding the root element. Creating one to load the app.");
	rootElement = document.createElement('div');
	rootElement.setAttribute("id", "root");
}

const root = createRoot(rootElement);

root.render(<App />);
