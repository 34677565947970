import React, {useEffect, useState, useContext} from 'react';
import {Button} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './PrintModal.scss';
import {retrieveSignedCIAF} from '../../../services/RetrieveSignedCIAF/RetrieveSignedCiaf';
import {GlobalContext} from '../../../../GlobalService';

const PrintModal = props => {

    const [bundleId, setBundleId] = useState("");
    const [bundleVersion, setBundleVersion] = useState("");
    const [vinNumber, setVinNumber] = useState("");
    const {logoutClicked, setLogoutClicked} = useContext(GlobalContext);

    useEffect(() => {
        setBundleId(props.acknowledgementData.bundleId);
        setBundleVersion(props.acknowledgementData.bundleVersionNbr);
        setVinNumber(props.acknowledgementData.vin);
    })

    const printfunctionality = () => {
        retrieveSignedCIAF(vinNumber, bundleId, bundleVersion).then((res) => {
            const byteCharacters = atob(res.data.data.ciafInfo[0].content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const file = new Blob([byteArray], {type: 'application/pdf;base64'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }).catch((error) => {
            console.error(error);
        })
    }

    const downloadFunctionality = () => {

        retrieveSignedCIAF(vinNumber, bundleId, bundleVersion).then((res) => {
            const byteCharacters = atob(res.data.data.ciafInfo[0].content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const file = new Blob([byteArray], {type: 'application/pdf;base64'});

            // Create a non-existent <a> tag, set the file name and href, then click to download file
            let anchor = document.createElement('a');
            anchor.download = props.vin + "_CIAF.pdf";
            anchor.href = URL.createObjectURL(file);
            anchor.dataset.downloadurl = ['application/pdf;base64', anchor.download, anchor.href].join(':');
            anchor.click();
            anchor = null;
        })
    }

    const logoutFunctionality = () => {
        setLogoutClicked(true);
        localStorage.clear();
    }

    if (!props.show) {
        return null
    }

    return (
        <div className="print-modal-container">

            <div className="print-modal-content">

                <div className="print-modal-header">

                    <div className="print-modal-title">
                        <CloseIcon onClick={props.onClose} className="print-modal-close-icon"/>
                        <h4>Confirmation</h4>
                    </div>

                </div>

                <div className="print-modal-body">
                    <p>Your submission of the Customer Information Acknowledgement Form was successful. <br/> <br/>
                        Please print or download a copy of this form for your records. If you need this form at a later
                        date please contact your dealer.
                    </p>
                </div>

                <div className="print-modal-buttons">
                    <div className='ind-button-div'><Button className="print-modal-print-button" variant="contained"
                                                            onClick={() => printfunctionality()}>Print</Button></div>
                    <div className='ind-button-div'><Button className="print-modal-download-button" variant="contained"
                                                            onClick={() => downloadFunctionality()}>Download</Button>
                    </div>
                    <div className='ind-button-div'><Button className="print-modal-logout-button" variant="contained"
                                                            onClick={() => logoutFunctionality()}>Logout</Button></div>
                </div>

                <div className="print-modal-footer">

                </div>

            </div>

        </div>
    )
}

export default PrintModal;