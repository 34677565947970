import { useEffect, useState, useContext } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Header from "../components/header/Header";
import AckForm from "../components/cusAcknowlegment/AckForm/AckForm";
import jwt_decode from "jwt-decode";
import ConfirmationPage from "../components/confirmationCode/ConfirmationCodePage";
import { getUserProfile } from "../services/getProfile/GetProfileService";
import LogoutPage from "../components/logoutPage/LogoutPage";
import { GlobalContext } from "../../GlobalService";
import {
    getAcknowledgementData,
    getCustomerInfoRead,
} from "../services/acknowledgementServices/AcknowledgementServices";
import { getFormattedDateNoUTC } from "../components/cusAcknowlegment/DateFormatter";

const Index = () => {
    let azureUrl = "";
    let clientID = "";
    let appurl = "";
    if (window.location.hostname === "localhost") {
        azureUrl = process.env.REACT_APP_AZURE;
        clientID = process.env.REACT_APP_AZURECLIENTID;
        appurl = process.env.REACT_APP_APP_URL;
    } else {
        azureUrl = window._env_.REACT_APP_AZURE;
        clientID = window._env_.REACT_APP_AZURECLIENTID;
        appurl = window._env_.REACT_APP_APP_URL;
    }

    let token;
    let tokenDecoded;
    let bundleId;
    let state;
    let cusInc = [];

    const [Authenticated, setAuthenticated] = useState(false);
    const [isToken, setIsToken] = useState(false);
    const policy = "B2C_1A_SEAMLESSMIGRATION_SIGNUPORSIGNIN&client_id=";
    const qparams = "&nonce=defaultNonce&redirect_uri=";
    const [isLoading, setisLoading] = useState(true);
    const [customerEmail, setCustomerEmail] = useState("");
    const [confirmedCode, setConfirmedCode] = useState(false);
    const {
        logoutClicked,
        vinBrand,
        setVinBrand,
        userData,
        setUserData,
        customerData,
        setCustomerData,
        userProfileId,
        setUserProfileId,
    } = useContext(GlobalContext);
    const [priceLevel, setPriceLevel] = useState("NON");
    const [priceLevelNum, setPriceLevelNum] = useState("");
    const [hasAuthCode, setHasAuthCode] = useState(false);
    const [incentiveData, setIncentiveData] = useState([]);
    const [discountInformation, setDiscountInformation] = useState([]);
    const [cdaTableData, setCDATableData] = useState({});
    const [customerIncentives, setCustomerIncentives] = useState([]);
    const [acknowledgementData, setAcknowledgementData] = useState({});
    const [discountProgram, setDiscountProgram] = useState({
        amountOne: "",
        amountTwo: "",
        amountThree: "",
        amountFour: "",
        amountFive: "",
        amountSix: "",
        totalAmount: "0",
        itemOne: "",
        itemTwo: "",
        itemThree: "",
        itemFour: "",
        itemFive: "",
        itemSix: "",
    });
    const [pricingForAll, setPricingForAll] = useState("N");
    const [formulaPricing, setFormulaPricing] = useState(false);
    const specialHandlingTemp = ["TX07", "TX21", "TX27", "TX36"];
    const gmCardTemplates = [
        "TX02",
        "TX03",
        "TX09",
        "TX10",
        "TX11",
        "TX25",
        "TX26",
        "TX41",
        "TX42",
    ];

    let email = "";

    const getToken = () => {
        const queryParams = new URLSearchParams(window.location.search);
        let brand = queryParams.get("brand");
        !brand
            ? sessionStorage.setItem("brand", brand)
            : sessionStorage.getItem("brand");
        bundleId = queryParams.get("bundleId");
        !bundleId
            ? sessionStorage.setItem("bundleId", bundleId)
            : sessionStorage.getItem("bundleId");
        let url =
            azureUrl +
            "B2C_1A_SEAMLESSMIGRATION_SIGNUPORSIGNIN&client_id=" +
            clientID +
            "&nonce=defaultNonce&redirect_uri=" +
            appurl +
            "&state=" +
            bundleId +
            "&bundleId=" +
            bundleId +
            "&scope=openid&response_type=id_token&channel=globalnav&ui_locales=en_US&brand=" +
            brand;
        const params = new URLSearchParams(window.location.hash);
        let sessionBundleId = sessionStorage.getItem("bundle");
        let sessionToken = sessionStorage.getItem("id_token");
        state = params.get("#state");
        !state && sessionStorage.getItem("confirmedCode") !== "set"
            ? (window.location.href = url)
            : (!sessionBundleId ||
                  sessionBundleId == "null" ||
                  (state && state !== "null")) &&
              sessionStorage.setItem("bundle", state);
        // TODO: Probably need to check expiration of token for resetting?
        token = params.get("id_token");
        !token && sessionStorage.getItem("confirmedCode") !== "set"
            ? (window.location.href = url)
            : !sessionToken &&
              sessionToken !== "null" &&
              sessionStorage.setItem("id_token", token);
        sessionStorage.getItem("id_token")
            ? (tokenDecoded = jwt_decode(sessionStorage.getItem("id_token")))
            : null;
        setAuthenticated(true);
        setIsToken(true);
    };

    function checkAuthCodes(incentive) {
        let hasAuthCodes = false;
        if (incentive.qualifierDataResponses?.length > 0) {
            incentive.qualifierDataResponses.map((qual) => {
                if (
                    qual.qualifierLabelNm === "Authorization Number" ||
                    qual.qualifierLabelNm === "Certificate Number"
                ) {
                    hasAuthCodes = true;
                }
            })
        } else if (incentive.qualifierDataResponses?.length === 0 && incentive.relationshipType !== "" && incentive.relationshipSnapshotId !== "") {
            hasAuthCodes = true
        }
        return hasAuthCodes;
    }

    const setFormData = () => {
        let totalVal = 0;
        let incArr = [];
        let cdaTable = {};
        let discountArry = [];
        let discountIncentive = {};
        let customerBirthday = null;
        getAcknowledgementData(sessionStorage.getItem("bundle"))
            .then((res) => {
                let response = res.data;
                let eligibleParticipant = null;
                setAcknowledgementData(response);
                setVinBrand(response?.make);
                getCustomerInfoRead(res?.data?.customerSnapshotId).then(
                    (res) => {
                        setCustomerData(res?.data?.data?.person);
                        customerBirthday =
                            res?.data?.data?.person?.date_of_birth;

                        response.acknowledgementIncentiveData.forEach((inc) => {
                            let specialHandlingDiscount = false;
                            inc.incentiveType = "ciaf";
                            if (inc?.recipientType === "CUS") {
                                if (inc?.transferred === null)
                                    inc.transferred = "N";
                                if (
                                    inc?.pricingLevelCode === "SUP" ||
                                    inc?.pricingLevelCode === "EMP" ||
                                    inc?.pricingLevelCode === "QRD"
                                ) {
                                    setPriceLevel(inc?.pricingLevelCode);
                                    setPriceLevelNum(inc?.progNum);
                                }
                                //Special handling statement
                                if (
                                    (inc?.pricingLevelCode === "SUP" ||
                                        inc?.pricingLevelCode === "EMP") &&
                                    inc?.programType === "TEXT" &&
                                    checkAuthCodes(inc)
                                ) {
                                    totalVal += parseFloat(0);
                                } else if (
                                    (inc?.pricingLevelCode === "SUP" ||
                                        inc?.pricingLevelCode === "EMP") &&
                                    inc.dealType === "TOD"
                                ) {
                                    setFormulaPricing(true);
                                    totalVal += parseFloat(inc.progVal);
                                } else {
                                    totalVal += parseFloat(inc.progVal);
                                }
                                incArr.push(inc);
                                if (
                                    inc?.qualifierDataResponses.length > 0 &&
                                    !gmCardTemplates.includes(
                                        inc?.defaultProgramName
                                    )
                                ) {
                                    inc?.qualifierDataResponses.forEach(
                                        (qual) => {
                                            if (
                                                qual?.qualifierLabelNm ===
                                                    "Authorization Number" ||
                                                qual?.qualifierLabelNm ===
                                                    "Certificate Number"
                                            ) {
                                                setHasAuthCode(true);
                                                inc.incentiveType = "affinity";
                                                let approvalDate = null;
                                                let approvalNum = "";
                                                if (
                                                    inc?.qualifierDataResponses.find(
                                                        (e) =>
                                                            e.qualifierLabelNm ===
                                                            "Approval Code"
                                                    )
                                                ) {
                                                    approvalNum =
                                                        inc?.qualifierDataResponses.find(
                                                            (e) =>
                                                                e.qualifierLabelNm ===
                                                                "Approval Code"
                                                        );
                                                    approvalNum =
                                                        approvalNum.qualifierVal;
                                                }
                                                if (inc.approvalDt !== null) {
                                                    approvalDate =
                                                        getFormattedDateNoUTC(
                                                            inc.approvalDt
                                                        );
                                                }
                                                if (
                                                    inc.relationshipSnapshotId
                                                ) {
                                                    getCustomerInfoRead(
                                                        inc.relationshipSnapshotId
                                                    ).then((res) => {
                                                        eligibleParticipant =
                                                            res?.data?.data
                                                                ?.person
                                                                ?.first_name +
                                                            " " +
                                                            res?.data?.data
                                                                ?.person
                                                                ?.last_name;
                                                        discountIncentive = {
                                                            approvalNumber:
                                                                qual?.voidApprovalCd,
                                                            approvalDate:
                                                                approvalDate,
                                                            authorizationNumber:
                                                                qual?.qualifierVal,
                                                            authType:
                                                                qual?.authType,
                                                            birthday:
                                                                customerBirthday,
                                                            participant:
                                                                eligibleParticipant,
                                                            incentiveCode:
                                                                inc?.incentiveCode,
                                                            postalCode:
                                                                response?.zipCode,
                                                            programName:
                                                                inc?.progName,
                                                            programNbr:
                                                                inc?.progNum,
                                                            pricingForAll: "N",
                                                            purchaseType:
                                                                inc?.purchaseType,
                                                            relationship:
                                                                inc?.relationshipType,
                                                            relationshipSnapshotId:
                                                                inc?.relationshipSnapshotId,
                                                        };
                                                        discountArry.push(
                                                            discountIncentive
                                                        );
                                                        setDiscountInformation({
                                                            ...discountInformation,
                                                            birthday:
                                                                customerBirthday,
                                                            discountArry:
                                                                discountArry,
                                                        });
                                                    });
                                                } else {
                                                    discountIncentive = {
                                                        approvalNumber:
                                                            approvalNum,
                                                        approvalDate:
                                                            approvalDate,
                                                        authorizationNumber:
                                                            qual?.qualifierVal,
                                                        authType:
                                                            qual?.authType,
                                                        birthday:
                                                            customerBirthday,
                                                        participant:
                                                            eligibleParticipant,
                                                        incentiveCode:
                                                            inc?.incentiveCode,
                                                        postalCode:
                                                            response?.zipCode,
                                                        programName:
                                                            inc?.progName,
                                                        programNbr:
                                                            inc?.progNum,
                                                        pricingForAll: "N",
                                                        purchaseType:
                                                            inc?.purchaseType,
                                                        purchaserName:
                                                            customerInformation?.firstName +
                                                            " " +
                                                            customerInformation?.lastName,
                                                        relationship:
                                                            inc?.relationshipType,
                                                        relationshipSnapshotId:
                                                            inc?.relationshipSnapshotId,
                                                    };
                                                    discountArry.push(
                                                        discountIncentive
                                                    );
                                                    setDiscountInformation({
                                                        ...discountInformation,
                                                        birthday:
                                                            customerBirthday,
                                                        discountArry:
                                                            discountArry,
                                                    });
                                                }
                                                setDiscountInformation({
                                                    ...discountInformation,
                                                    birthday: customerBirthday,
                                                    discountArry: discountArry,
                                                });
                                            }
                                        }
                                    );
                                } else if (inc?.qualifierDataResponses.length === 0 && inc?.relationshipType !== "" && inc?.relationshipSnapshotId !== "") {
                                    setHasAuthCode(true);
                                    inc.incentiveType = "affinity";
                                    getCustomerInfoRead(inc.relationshipSnapshotId)
                                        .then((res) => {
                                            eligibleParticipant = res?.data?.data?.person?.first_name + " " + res?.data?.data?.person?.last_name;
                                            discountIncentive = {
                                                approvalNumber: "",
                                                approvalDate: "",
                                                authorizationNumber: "",
                                                authType: "",
                                                birthday: customerBirthday,
                                                participant: eligibleParticipant,
                                                incentiveCode: inc.incentiveCode,
                                                postalCode: response.zipCode,
                                                programName: inc.progName,
                                                programNbr: inc.progNum,
                                                pricingForAll: "N",
                                                purchaseType: inc.purchaseType,
                                                relationship: inc.relationshipType,
                                                relationshipSnapshotId: inc.relationshipSnapshotId,
                                            }
                                            discountArry.push(discountIncentive);
                                            setDiscountInformation({
                                                ...discountInformation,
                                                birthday: customerBirthday,
                                                discountArry: discountArry,
                                            });
                                        })
                                    }
                                if (specialHandlingDiscount) {
                                    discountIncentive = {
                                        approvalNumber: "N/A",
                                        approvalDate: new Date(),
                                        authorizationNumber: "N/A",
                                        participant: "N/A",
                                        authType: "",
                                        incentiveCode: inc.incentiveCode,
                                        postalCode: response.zipCode,
                                        pricingForAll: "Y",
                                        programName: inc.progName,
                                        programNbr: inc.progNum,
                                        purchaseType: inc.purchaseType,
                                        purchaserName: "N/A",
                                        relationship: "N/A",
                                        relationshipSnapshotId: "",
                                    };
                                    discountArry.push(discountIncentive);
                                    setDiscountInformation({
                                        ...discountInformation,
                                        birthday: customerBirthday,
                                        discountArry: discountArry,
                                    });
                                }
                            }
                        });
                        if (response.employeeDiscountInfo !== null) {
                            response.employeeDiscountInfo.employeeDiscounts.forEach(
                                (discount) => {
                                    if (discount.lineItemCode === "LineItem1") {
                                        cdaTable.amountOne =
                                            discount.itemChargeTypeAmount;
                                        cdaTable.itemOne =
                                            discount.itemChargeType;
                                    } else if (
                                        discount.lineItemCode === "LineItem2"
                                    ) {
                                        cdaTable.amountTwo =
                                            discount.itemChargeTypeAmount;
                                        cdaTable.itemTwo =
                                            discount.itemChargeType;
                                    } else if (
                                        discount.lineItemCode === "LineItem3"
                                    ) {
                                        cdaTable.amountThree =
                                            discount.itemChargeTypeAmount;
                                        cdaTable.itemThree =
                                            discount.itemChargeType;
                                    } else if (
                                        discount.lineItemCode === "LineItem4"
                                    ) {
                                        cdaTable.amountFour =
                                            discount.itemChargeTypeAmount;
                                        cdaTable.itemFour =
                                            discount.itemChargeType;
                                    } else if (
                                        discount.lineItemCode === "LineItem5"
                                    ) {
                                        cdaTable.amountFive =
                                            discount.itemChargeTypeAmount;
                                        cdaTable.itemFive =
                                            discount.itemChargeType;
                                    } else if (
                                        discount.lineItemCode === "LineItem6"
                                    ) {
                                        cdaTable.amountSix =
                                            discount.itemChargeTypeAmount;
                                        cdaTable.itemSix =
                                            discount.itemChargeType;
                                    }
                                    cdaTable.totalAmount =
                                        parseFloat(cdaTable.amountOne) +
                                        parseFloat(cdaTable.amountTwo) +
                                        parseFloat(cdaTable.amountThree) +
                                        parseFloat(cdaTable.amountFour) +
                                        parseFloat(cdaTable.amountFive) +
                                        parseFloat(cdaTable.amountSix);
                                }
                            );
                            setDiscountProgram(cdaTable);
                        }
                        incArr.push({ totalVal: totalVal });
                        setIncentiveData(incArr);
                        setisLoading(false);
                    }
                );
            })
            .catch((error) => {});
    };

    useEffect(() => {
        setisLoading(true);
        getToken();
        email = tokenDecoded.email;
        setCustomerEmail(email);

        if (userProfileId) {
            getUserProfile(userProfileId).then((res) => {
                setUserData({
                    ...res?.data.data.response_data.data.person,
                    email_address:
                        res?.data.data.response_data.data.email_address,
                });
            });
        }
        setFormData();
    }, [userProfileId]);

    useEffect(() => {
        acknowledgementData?.acknowledgementIncentiveData?.map((inc) => {
            if (inc.recipientType === "CUS") {
                cusInc.push(inc);
            }
        });
        cusInc.push(acknowledgementData?.acknowledgementIncentiveData?.at(-1));
        setCustomerIncentives(cusInc);
    }, [acknowledgementData?.acknowledgementIncentiveData]);

    return (
        <div className="index">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {Authenticated &&
                    isToken &&
                    !confirmedCode &&
                    sessionStorage.getItem("confirmedCode") !== "set" && (
                        <ConfirmationPage
                            email={customerEmail}
                            passConfirmedCode={setConfirmedCode}
                            acknowledgementData={acknowledgementData}
                        />
                    )}
                {(confirmedCode ||
                    sessionStorage.getItem("confirmedCode") === "set") &&
                    userData &&
                    !logoutClicked && (
                        <>
                            <Header acknowledgementData={acknowledgementData} />
                            {JSON.stringify(acknowledgementData) !== "{}" &&
                                incentiveData.length > 0 && (
                                    <AckForm
                                        acknowledgementData={
                                            acknowledgementData
                                        }
                                        incentiveData={incentiveData}
                                        priceLevel={priceLevel}
                                        discountInformation={
                                            discountInformation
                                        }
                                        setDiscountInformation={
                                            setDiscountInformation
                                        }
                                        customerIncentives={customerIncentives}
                                        hasAuthCode={hasAuthCode}
                                        priceLevelNum={priceLevelNum}
                                        cdaTableData={cdaTableData}
                                        discountProgram={discountProgram}
                                        setDiscountProgram={setDiscountProgram}
                                        pricingForAll={pricingForAll}
                                        userData={userData}
                                        customerData={customerData}
                                        formulaPricing={formulaPricing}
                                    />
                                )}
                        </>
                    )}
                {logoutClicked && (
                    <>
                        <Header acknowledgementData={acknowledgementData} />
                        <LogoutPage acknowledgementData={acknowledgementData} />
                    </>
                )}
            </LocalizationProvider>
        </div>
    );
};
export default Index;
