/**
 * Formats date to MM/DD/YYYY format
 * To use, import getFormattedDate and just call the function,
 * example in ProgramVehicleInfo.jsx
 * @param {*} date
 * @returns date in MM/DD/YYYY formate
 */
export const getFormattedDate = (date1) => {
    if (date1 && date1 !== "N/A") {
        const newDate = new Date(date1);
        const year = newDate.getFullYear();
		const month = (1 + newDate.getMonth()).toString().padStart(2, "0");
		const day = newDate.getDate().toString().padStart(2, "0");
        return month + '/' + day + '/' + year;
    } else {
        return ''
    }
}

export const getFormattedDateNoUTC = (date1) => {
	if (date1 && date1 !== "N/A") {
		const newDateArray = date1.split('-');
		return newDateArray[1] + '/' + newDateArray[2] + '/' + newDateArray[0];
	} else {
		return ''
	}
}


export const dashFormattedDate = (date) => {
	if (date && date !== "N/A") {
		const newDate = new Date(date);
		const year = newDate.getFullYear();
		const month = (1 + newDate.getMonth()).toString().padStart(2, "0");
		const day = newDate.getDate().toString().padStart(2, "0");

		return `${year}-${month}-${day}`;
	}
	return "";
}

/* Formats date when date is not sent with timestamp
 * Expected input format: yyyy-mm-dd
 */
export function displayDateFormat(date) {
	return `${date.slice(5, 7)}/${date.slice(8, 10)}/${date.slice(0, 4)}`;
}