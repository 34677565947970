import React, {useState, useEffect, useContext} from "react";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import {CUSTOMER_AGREEMENT, T27_CUSTOMER_AGREEMENT} from "../../common/AppConstants"
import {GlobalContext} from "../../../GlobalService"
import "../cusAcknowlegment/AckForm/AckForm.scss";
import DocFeeDisclosure from "./DocFeeDisclosure";
import { getFormattedDate } from "./DateFormatter";

const CustomerAgreement = ({priceLevel,
                            setDiscountProgram,
                            discountProgram,
                            getProgramType,
                            acknowledgementData,
                            incentiveData,
                            docFeeDisclosure,
                            setDocFeeDisclosure,
                            hasAuthCode,
                            pricingForAll,
                            formulaPricing}) => {
    const [disclosureType, setDisclosureType] = useState("");
    const [programType, setProgramType] = useState("");
    const [boxChecked, setBoxChecked] = useState(false);
    const {signatureURL, completeForm, setDisableCustomerAgreement,} = useContext(GlobalContext);
    const [agreement, setAgreement] = useState(null);
    const [hasGmDealershipEmployeeST, setHasGmDealershipEmployeeST] = useState(false);

    const handleCustomerChange = () => {
        setBoxChecked(!boxChecked);
    }

    const fontColor = {
        style: {color: 'rgb(50, 50, 50)'}
    }
    let currentDate = new Date();

    const getAgreement = () => {
        let tempAgrmnt = ((priceLevel === "SUP" || priceLevel === "EMP") && !hasGmDealershipEmployeeST) ? CUSTOMER_AGREEMENT : T27_CUSTOMER_AGREEMENT;
        return programType == "Affinity" ? "" : tempAgrmnt;
    }

    function formatDate(tempDate) {
        let date = new Date(tempDate);
        let result = date.toLocaleDateString(undefined, { // you can use undefined as first argument
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        })

        return result;
    }

    useEffect(() => {
        if(getProgramType() !== "CIAF" || pricingForAll === "Y"){
            if (boxChecked === true) {
                setDisableCustomerAgreement(false);
            } else {
                setDisableCustomerAgreement(true);
            }
        } else  {
            setDisableCustomerAgreement(false);
        }   
    }, [boxChecked]);

    useEffect(() => {
        setAgreement(getAgreement());
    }, [hasGmDealershipEmployeeST, programType])

    useEffect(() => {

        setProgramType(getProgramType(priceLevel, hasAuthCode));

        const andStackabilityTemplates = [
            "CS80", "CS79", "TX22", "TX24", "TX23",
            "CS78", "CS77", "CS72", "CS71", "TX18",
            "CS69", "CS68", "CS70", "CS60"
        ];

        const orStackabilityTemplates = [
            "CS75", "CS73", "TX34", "CS76", "CS74",
            "TX35", "CS67", "CS66"
        ];

        const gmDealershipEmployeeST = ["TX18", "CS72", "CS71"];

        let discType = "";

        acknowledgementData.acknowledgementIncentiveData.forEach(incentive => {

            if (gmDealershipEmployeeST.includes(incentive.defaultProgramName)) {
                setHasGmDealershipEmployeeST(true);
            }

            if (discType == "") {
                if (andStackabilityTemplates.includes(incentive.defaultProgramName) &&  incentive.recipientType === "CUS") {
                    discType = "AND";
                    setDocFeeDisclosure("and");
                }

                if (orStackabilityTemplates.includes(incentive.defaultProgramName) &&  incentive.recipientType === "CUS") {
                    discType = "OR";
                    setDocFeeDisclosure("or");
                };
            }
            setDisclosureType(discType)

        });

    }, [incentiveData]);

    return (
        <div>
            <div className="group-wrapper">
                {pricingForAll !== "Y" && (
                    <div className="input-field-header-black">
                        Customer Agreement and Verification of Delivery
                    </div>)}

                {programType == "Affinity" &&
                (<><p>
                    The program does not require a CDA. You must retain a copy of this transaction for your records.
                </p><br/></>)}
                {(pricingForAll !== "Y"  && !formulaPricing) && agreement}
                {((pricingForAll !== "Y" && !formulaPricing)  && docFeeDisclosure !== "none") &&
                <p>Dealers will be permitted to assess a maximum of $75 in documentation fees, except when:</p>}
                {((programType !== "CIAF" && disclosureType !== "" && pricingForAll !== "Y") || (!formulaPricing)) && (
                    <DocFeeDisclosure
                        disclosureType={disclosureType}
                        discountProgram={discountProgram}
                        setDiscountProgram={setDiscountProgram}
                        priceLevel={priceLevel}
                        customerIncentives={incentiveData}
                        programType={programType}
                        acknowledgementData={acknowledgementData}
                    />
                )}

                <div className="checkbox_area">
                    <Checkbox
                        data-testid="docFeeCheckbox"
                        style={{
                            transform: "scale(1.2)",
                            padding: 0,
                        }}
                        color="primary"
                        checked={boxChecked}
                        onChange={handleCustomerChange}

                    /> Purchaser acknowledges that all the information
                    above is correct.
                </div>
                <div>
                    <Grid container spacing={6}>
                        <Grid item xs={4} sm={3}>
                            <div className="input-label">Date</div>
                            {getFormattedDate(currentDate)}
                        </Grid>
                        <Grid item xs={8} sm={9}>
                            <div className="input-label">By signing below you are acknowledging and agreeing to the information provided above:
                            </div>
                            {!completeForm ?
                                <TextField
                                    id="customer-signature-box"
                                    variant="outlined"
                                    disabled
                                    size="small"
                                    fullWidth>
                                </TextField> :
                                <div className="signature-container">
                                    <img
                                        src={signatureURL}
                                        alt="my signature"
                                        className="signatureimg2"
                                    />
                                </div>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className="group-bottom"></div>
                <Divider/>
            </div>
            {(programType == "Affinity") &&(disclosureType !== "" || docFeeDisclosure === "none")&& pricingForAll === "N" && (
                <div className="group-wrapper">
                    <div className="input-field-header-black">
                        Dealer Agreement
                    </div>
                    <div className="checkbox_area">
                        <Checkbox
                        style={{
                            transform: "scale(1.2)",
                            padding: 0,
                        }}
                        color="primary"
                        checked={true}
                    />&nbsp;&nbsp;
                        The authorized Dealer acknowledges that all the information
                        above is correct.
                    </div>
                    <div>
                        <Grid container spacing={6}>
                            <Grid item xs={4} sm={3}>
                                <div className="input-label">Date</div>
                                <div className="field-value">{formatDate(currentDate)}</div>
                            </Grid>
                            <Grid item xs={8} sm={9}>
                                <div className="input-label">
                                    By signing below you are acknowledging and agreeing
                                    to the information provided above:
                                </div>
                                <TextField
                                    id="customer-signature-box"
                                    value={acknowledgementData.dealerNm}
                                    disabled
                                    variant="outlined"
                                    inputProps={fontColor}
                                    size="small"
                                    fullWidth
                                ></TextField>
                            </Grid>
                        </Grid>
                    </div>
                </div>)
            }

            <div className="group-bottom"></div>
            <Divider/>
        </div>
    );
};

export default CustomerAgreement;
