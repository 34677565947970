import axios from "axios";

export async function getUserProfile(profileId) {

    let gateway = "";
    if (window.location.hostname === "localhost") {
        gateway = process.env.REACT_APP_BACKEND_GATEWAY;
    } else {
        gateway = window._env_.REACT_APP_BACKEND_GATEWAY;
    }

    const response = await axios({
        method: "get",
        url: gateway + "/customer/cdi/v3/read-profile/PROFILE/" + profileId + "/PROFILE",
        timeout: 20000,
        headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("id_token"),
        }
    });

    return response;
}

export async function getUserProfileId(email) {

    let gateway = "";
    if (window.location.hostname === "localhost") {
        gateway = process.env.REACT_APP_BACKEND_GATEWAY;
    } else {
        gateway = window._env_.REACT_APP_BACKEND_GATEWAY;
    }

    const response = await axios({
        method: "get",
        url: gateway + "/customer/cdi/identity/" + email,
        timeout: 20000,
        headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("id_token"),
        }
    });


    return response;
}