import { useEffect, useState } from "react";
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import "../cusAcknowlegment/AckForm/AckForm.scss";

const DealerInfo = (props) => {
    const [dealerName, setDealerName] = useState("");
    const [bac, setBac] = useState("");
    const [division, setDivision] = useState("");
    const [dealerCode, setDealerCode] = useState("");
    const [sellSource, setSellSource] = useState("");

    useEffect(() => {
        if (props?.acknowledgementData) {
            if (props?.acknowledgementData?.make === "Chevy") {
                setDivision("Chevrolet")
            } else {
                setDivision(props.acknowledgementData?.make);
            }

            setDealerCode(props.acknowledgementData?.siteCode);
            setSellSource(props.acknowledgementData?.sellSource);
            setBac(props.acknowledgementData?.bacCode.replace(/^0+/, ""));
            setDealerName(props.acknowledgementData?.dealerNm)

        }
    }, []);

    useEffect(() => {
        props.dealerInformation.dealerName = dealerName;
        props.dealerInformation.bac = bac;
        props.dealerInformation.division = division;
        props.dealerInformation.dealerCode = dealerCode;
        props.dealerInformation.sellSource = sellSource;
    }, [dealerName, bac, division, dealerCode, sellSource]);


    return (
        <div className="group-wrapper">
            <div className="input-field-header">Dealer Information</div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div className="input-label">Dealer Name</div>
                    <div id="dealer-name-box" className="field-value">{dealerName}</div>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <div className="input-label">Division</div>
                    <div id="division-box" className="field-value">{division}</div>
                </Grid>
                <Grid item xs={4}>
                    <div className="input-label">BAC</div>
                    <div id="bac-box" className="field-value">{bac}</div>
                </Grid>
                <Grid item xs={4}>
                    <div className="input-label">Dealer Code</div>
                    <div id="dealer-code-box" className="field-value">{dealerCode}</div>
                </Grid>
            </Grid>
            <div className="group-bottom"></div>
            <Divider/>
        </div>
    );
};

export default DealerInfo