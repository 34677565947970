import {useState, useContext, useRef} from "react";
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import SignaturePad from "react-signature-canvas";
import "../cusAcknowlegment/AckForm/AckForm.scss";
import {GlobalContext} from "../../../GlobalService";

const CustomerInitials = () => {

    const sigCanvas = useRef({});

    const [openPopup, setOpenPopup] = useState(false);
    const [isEmpty, setIsEmpty] = useState(true);
    const {setInitialURL} = useContext(GlobalContext)

    const handleClick = () => {
        setOpenPopup(true);
    };

    const handleClose = (event) => {
        setIsEmpty(true);
        setOpenPopup(false);
    };

    const clear = () => {
        setIsEmpty(true);
        sigCanvas.current.clear();
    }
    const save = () => setInitialURL(sigCanvas.current.toDataURL("image/png"));

    return (
        <div className="initails">
            <Button className="initialBox" variant="outlined" onClick={() => handleClick()}>Initial here</Button>
                <Popover
                  open={openPopup}
                  onClose={(e) => handleClose()}>
                    <SignaturePad
                        ref={sigCanvas}
                        canvasProps={{ className: "initialCanvas" }}
                        onBegin={() => setIsEmpty(false)}
                        clearOnResize={false}
                    />
                    <Button onClick={handleClose}>close</Button>
                    <Button onClick={clear}>Clear</Button>
                    <Button onClick={save} disabled={isEmpty}>Save</Button>
                </Popover>
        </div>

    );
}

export default CustomerInitials;