import axios from "axios";

export async function sendCode(profileId, email) {

    const req = {
        "channel": "EMAIL",
        "phoneNumber": "",
        "profileId": profileId,
        "email": email
    };


    let gateway = "";
    if (window.location.hostname === "localhost") {
        gateway = process.env.REACT_APP_BACKEND_GATEWAY;
    } else {
        gateway = window._env_.REACT_APP_BACKEND_GATEWAY;
    }
    const payload = JSON.stringify(req)
    const response = await axios({
        method: "post",
        data: payload,
        url: gateway + "/customer/cdi/otp/send",
        headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("id_token"),
        }
    });

    return response;
}

export async function confirmCode(code, profileId) {

    const req = {
        "code": code,
        "profileId": profileId
    };


    let gateway = "";
    if (window.location.hostname === "localhost") {
        gateway = process.env.REACT_APP_BACKEND_GATEWAY;
    } else {
        gateway = window._env_.REACT_APP_BACKEND_GATEWAY;
    }
    const payload = JSON.stringify(req)
    const response = await axios({
        method: "post",
        data: payload,
        url: gateway + "/customer/cdi/otp/confirm",
        headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("id_token"),
        }
    });

    return response;
}