import React, {useContext} from "react";
import {GlobalContext} from "../../../GlobalService";
import "./LogoutPage.scss";

const LogoutPage = () => {
    const {vinBrand} = useContext(GlobalContext);

    const renderLogo = () => {
        let image;
        switch (vinBrand) {
            case "Cadillac":
            case "Buick":
            case "GMC":
            case "Chevrolet":
                image = require(`../../images/${vinBrand}/header-logo.png`);
                break;
            default:
                image = require(`../../images/GM/header-logo.png`);
        }
        return image;
    };

    return (
        <div className="logout-page">
            <div className="logout-page-logo">
                <img
                    className="logo-image"
                    src={renderLogo()}
                    alt="Logo"
                />
            </div>

            <div className="logout-message">
                <h3>You have successfully logged out of MyAccount</h3>
            </div>
        </div>
    );
};

export default LogoutPage;
